export default [
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
    Permission: '',
  },

  {
    title: 'Contacts',
    icon: 'UsersIcon',
    Permission: '',
    children: [
      {
        title: 'Add',
        route: 'contact-add',
        icon: 'UserPlusIcon',
        Permission: '',
      },
      {
        title: 'List',
        route: 'contact-list',
        icon: 'ListIcon',
        Permission: '',
      },
      {
        title: 'Edit',
        route: 'contact-edit',
        icon: 'EditIcon',
        disabled: true,
        Permission: '',
      },
    ],
  },

  {
    title: 'Todo',
    route: 'todo-dashboard',
    icon: 'CheckSquareIcon',
    Permission: '',
  },

  {
    title: 'Files',
    route: 'file-list',
    icon: 'FolderIcon',
    Permission: '',
  },

  {
    title: 'Projects',
    route: 'project-list',
    icon: 'BoxIcon',
    Permission: '',
  },

  {
    title: 'Invoices',
    route: 'invoice-list',
    icon: 'ClipboardIcon',
    Permission: '',
  },

  {
    title: 'Quotes',
    route: 'quote-list',
    icon: 'FileIcon',
    Permission: '',
  },

  {
    title: 'Scopes',
    icon: 'InboxIcon',
    Permission: '',
    children: [
      {
        title: 'Create Scope',
        route: 'scope-add',
        icon: 'DatabaseIcon',
        Permission: '',
      },

      {
        title: 'Scope List',
        route: 'scopes-list',
        icon: 'HashIcon',
        Permission: '',
      },

      {
        title: 'Edit',
        route: 'scopes-edit',
        icon: 'InboxIcon',
        disabled: true,
        Permission: '',
      },
    ],
  },

  {
    title: 'Leads',
    icon: 'InboxIcon',
    Permission: '',
    children: [
      {
        title: 'Create Lead',
        route: 'leads-add',
        icon: 'DatabaseIcon',
        Permission: '',
      },
      {
        title: 'Leads',
        route: 'leads-dashboard',
        icon: 'HashIcon',
        Permission: '',
      },

      // {
      //   title: 'Leads List',
      //   route: 'leads-list',
      //   icon: 'HashIcon',
      //   Permission: '',
      // },

      {
        title: 'Edit',
        route: 'leads-edit',
        icon: 'InboxIcon',
        disabled: true,
        Permission: '',
      },
    ],
  },

  {
    title: 'Clockify',
    icon: 'ClockIcon',
    Permission: '',
    children: [
      {
        title: 'Clients',
        route: 'clockify-client',
        icon: 'UserPlusIcon',
        Permission: '',
      },
      {
        title: 'Projects',
        route: 'clockify-project',
        icon: 'ListIcon',
        Permission: '',
      },
      {
        title: 'User Report',
        route: 'clockify-user-reports',
        icon: 'ListIcon',
        Permission: '',
      },

      {
        title: 'Client Report',
        route: 'clockify-client-reports',
        icon: 'ListIcon',
        Permission: '',
      },
    ],
  },

  {
    title: 'Users',
    icon: 'UsersIcon',
    Permission: '',
    children: [
      {
        title: 'Add',
        route: 'user-add',
        icon: 'UserPlusIcon',
        Permission: '',
      },
      {
        title: 'List',
        route: 'user-list',
        icon: 'ListIcon',
        Permission: '',
      },
      {
        title: 'Edit',
        route: 'user-edit',
        icon: 'EditIcon',
        disabled: true,
        Permission: '',
      },
    ],
  },

  {
    title: 'Sign Out',
    route: 'signout',
    icon: 'LogOutIcon',
    Permission: '',
  },
];
